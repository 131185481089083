/** @jsx jsx */
import Layout from '../layouts/Layout';
import { jsx } from '@emotion/core';
import { ElementList } from '@nimles/react-web-components';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestUserPosition } from '../redux/geo';
import { PageModel } from '@nimles/models/lib/cms';
import { getImageUrl } from '../utils';
import { gql, useQuery } from '@apollo/react-hooks';
import queryString from 'query-string';
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import ApolloClient from 'apollo-boost';
import fetch from 'isomorphic-fetch';
import { ApolloProvider } from '@apollo/react-hooks';
import { RootState } from '../redux/types';
import { enableDraft } from '../redux/draft';
import styled from '@emotion/styled';
import { OrganizationMapPage } from './pages/OrganizationMapPage';
import { SignInPage } from './pages/SignInPage';
import { graphql } from 'gatsby';

export const client: any = new ApolloClient({
  uri: 'https://api.nimles.com/cms/public/graphql',
  fetch,
});

const pageQuery = gql`
  query GetPage($tenantId: Uuid!, $id: Uuid!) {
    page(tenantId: $tenantId, id: $id, draft: true) {
      id
      organizationId
      pageType
      title
      titleLocalized
      uniqueName
      elements {
        id
        type
        uniqueName
        content
        contentLocalized
        contentId
        css
        styles
        properties
        children {
          id
          type
          uniqueName
          content
          contentLocalized
          contentId
          css
          styles
          properties
          children {
            id
            type
            uniqueName
            content
            contentLocalized
            contentId
            css
            styles
            properties
            children {
              id
              type
              uniqueName
              content
              contentLocalized
              contentId
              css
              styles
              properties
              children {
                id
                type
                uniqueName
                content
                contentLocalized
                contentId
                css
                styles
                properties
                children {
                  id
                  type
                  uniqueName
                  content
                  contentLocalized
                  contentId
                  css
                  styles
                  properties
                  children {
                    id
                    type
                    uniqueName
                    content
                    contentLocalized
                    contentId
                    css
                    styles
                    properties
                    children {
                      id
                      type
                      uniqueName
                      content
                      contentLocalized
                      contentId
                      css
                      styles
                      properties
                      children {
                        id
                        type
                        uniqueName
                        content
                        contentLocalized
                        contentId
                        css
                        styles
                        properties
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Page = ({ pageContext, location }) => {
  const dispatch = useDispatch();
  const { draft }: any = location
    ? queryString.parse(location.search)
    : { draft: false };
  const isDraft = useSelector<RootState, boolean>(({ draft }) => draft);

  let {
    page,
  }: {
    page: PageModel;
  } = pageContext;

  const skip = !draft && !isDraft;
  const { data } = useQuery<{ page: PageModel }>(pageQuery, {
    variables: { tenantId: process.env.GATSBY_TENANT, id: page?.id },
    skip,
  });

  const { language } = useContext(I18nextContext);

  if (data?.page) {
    page = data.page || page;
  }

  useEffect(() => {
    navigator.permissions
      ?.query({
        name: 'geolocation',
      })
      .then(
        (permission) =>
          permission.state === 'granted' && dispatch(requestUserPosition())
      );
  }, []);

  const meta = {
    title: (language && page.longTitleLocalized?.[language]) || page.longTitle,
    description: page?.summary,
    image: getImageUrl(page?.imageId),
  };

  const PageComponent = ({
    page,
    location,
  }: {
    page: PageModel;
    location: Location;
  }) => {
    if (!page) {
      return null;
    }

    switch (page.pageType) {
      case 'Map':
        return <OrganizationMapPage page={page} location={location} />;
      case 'SignIn':
        return <SignInPage page={page} />;
      default:
        return null;
    }
  };

  return (
    <Layout
      header
      footer={page?.pageType !== 'Map'}
      meta={meta}
      location={location}
      headerAlwaysOpen={page?.pageType !== 'FirstPage'}
    >
      <ElementList
        tenantId={process.env.GATSBY_TENANT}
        elements={page?.elements}
        location={location}
        culture={language}
        root="Page"
      />
      <PageComponent page={page} location={location} />
    </Layout>
  );
};

const PageTemplate = (props) => (
  <ApolloProvider client={client}>
    <Page {...props} />
  </ApolloProvider>
);

export default PageTemplate;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
