import { PageModel, OrganizationModel, CategoryModel } from '@nimles/models';
import { useStaticQuery, graphql } from 'gatsby';
import React, { useMemo } from 'react';
import queryString from 'query-string';
import { OrganizationMap } from '../../components/organization/map/OrganizationMap';
import { toUniqueName } from '../../utils';

export const OrganizationMapPage = ({
  page,
  location: { search },
}: {
  page: PageModel;
  location: Location;
}) => {
  const { query: startQuery, category, region }: any =
    search && queryString.parse(search);

  const data = useStaticQuery(graphql`
    query OrganizationsQuery {
      organizationManagement {
        categories {
          edges {
            node {
              id
              name
              nameLocalized
              longName
              longNameLocalized
              summary
              summaryLocalized
              description
              descriptionLocalized
              uniqueName
              bannerId
              logoId
              tags
            }
          }
        }
        organizations {
          edges {
            node {
              id
              name
              description
              uniqueName
              phoneNumber
              website
              bannerId
              address {
                id
                street
                postalCode
                city
                state
              }
              location {
                id
                latitude
                longitude
              }
              socialLinks
              tags
            }
          }
        }
      }
    }
  `);

  const organizations: OrganizationModel[] = data.organizationManagement.organizations.edges.map(
    ({ node }) => node
  );

  const categories: CategoryModel[] = data.organizationManagement.categories.edges.map(
    ({ node }) => node
  );

  const selectedCategory =
    category && categories.find(({ uniqueName }) => uniqueName === category);

  return (
    <OrganizationMap
      organizations={organizations}
      categories={categories}
      selectedCategory={selectedCategory}
    />
  );
};
