import styled from '@emotion/styled';
import { Link } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { MapInfoWindow } from '../../map/MapInfoWindow';
import { MapMarker } from '../../map/MapMarker';

const Description = styled.div`
  flex: 1 0 auto;
  position: relative;
  font-size: 14px;
  line-height: 18px;
  max-height: 85px;
  overflow: hidden;
  margin-bottom: 5px;

  &::after {
    content: '';
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      transparent 100%
    );
    display: block;
    position: absolute;
    height: 10px;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export const OrganizationMarker = ({
  organization,
  location,
  isSelected,
  onSelect,
  icon,
}) => {
  const {
    name,
    uniqueName,
    description,
    address: { street, postalCode, city },
    tags,
  } = organization;
  return (
    <MapMarker
      location={location}
      icon={icon}
      onClick={() => onSelect(isSelected ? null : organization)}
    >
      {isSelected ? (
        <MapInfoWindow onClose={() => onSelect(null)} location={location}>
          <div>
            <div>
              <Link to={`/${uniqueName}`}>
                <strong>{name}</strong>
              </Link>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <div>
              {street}, {postalCode} {city}
            </div>
          </div>
        </MapInfoWindow>
      ) : null}
    </MapMarker>
  );
};
