import { LocationModel } from '@nimles/models';
import { InfoWindow } from '@react-google-maps/api';
import React, { FC } from 'react';
import { toLatLng } from './Map';

interface Props {
  location: LocationModel;
  onClose: () => void;
}
export const MapInfoWindow: FC<Props> = ({ onClose, children, location }) => {
  return (
    <InfoWindow onCloseClick={onClose} position={toLatLng(location)}>
      {children}
    </InfoWindow>
  );
};
