import React, { FC } from 'react';
import { LocationModel } from '@nimles/models';
import { Marker } from '@react-google-maps/api';
import { toLatLng } from './Map';

interface Props {
  icon?: any;
  location: LocationModel;
  onClick?: (e: any) => void;
}

export const MapMarker: FC<Props> = ({ location, onClick, children, icon }) => {
  return (
    <Marker position={toLatLng(location)} onClick={onClick} icon={icon}>
      {children}
    </Marker>
  );
};
