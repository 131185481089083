import React from 'react';
import {
  Card,
  Container,
  CardBody,
  CardTitle,
  CardHeader,
  Column,
  Row,
  Section,
} from '@nimles/react-web-components';
import { navigate } from 'gatsby';
import { useDispatch } from 'react-redux';
import {
  forgotPassword,
  loadCurrentUser,
  signInUser,
} from '@nimles/react-redux';
import { PageModel } from '@nimles/models';
import { SignInForm } from '../../components/forms/signIn/SignInForm';
import { getImageUrl } from '../../utils';
import styled from '@emotion/styled';

interface Props {
  page: PageModel;
}

export const PageSection = styled(Section)`
  min-height: 80vh;
  padding: 100px 0 0;
`;

export const SignInPage = ({ page }) => {
  const dispatch = useDispatch();

  const meta = {
    title: page?.title,
    description: page?.summary,
    image: getImageUrl(page?.imageId),
  };

  const handleSubmit = async (values: any) => {
    await dispatch(signInUser(values));
    await dispatch(loadCurrentUser());
    navigate('/account/profile');
  };

  const handleForgotPassword = async (username: string) => {
    await dispatch(forgotPassword(username));
  };

  return (
    <PageSection>
      <Container flex justify="center" display="flex">
        <Row justify="center">
          <Column xs={100} sm={90} md={70} lg={60} xl={50} xxl={40}>
            <Card>
              <CardHeader>
                <CardTitle>Log in</CardTitle>
              </CardHeader>
              <CardBody>
                <SignInForm
                  onSubmit={handleSubmit}
                  onForgotPassword={handleForgotPassword}
                />
              </CardBody>
            </Card>
          </Column>
        </Row>
      </Container>
    </PageSection>
  );
};
