import React, { FC, useState } from 'react';
import { Column, Row, ListItem } from '@nimles/react-web-components';
import styled from '@emotion/styled';
import { rgba } from 'polished';
import { getImageUrl } from '../../../utils';
import { Link } from 'gatsby-plugin-react-i18next';

const StyledListItem = styled(ListItem)`
  color: ${({ theme }) => theme.colors.surface.onColor};
  background-color: ${({ active, theme }) =>
    active ? rgba(theme.colors.primary.color, 0.1) : 'unset'};
  display: flex;
  cursor: pointer;
  border-left: 4px solid
    ${({ active, theme }) =>
      active ? theme.colors.primary.color : 'transparent'};
  border-bottom: 1px solid #ccc;
  padding: 5px;
`;

const OrganizationRow = styled(Row)`
  margin: 0 -5px;
`;

const Image = styled.img`
  width: 100px;
  height: 60px;
  object-fit: cover;
  padding: 0 5px;
`;

const TextColumn = styled(Column)`
  padding: 0 5px;
`;

const Description = styled.div<{ isOpen: boolean }>`
  flex: 1 0 auto;
  position: relative;
  font-size: 14px;
  line-height: 18px;
  max-height: ${({ isOpen }) => (isOpen ? 'auto' : '41px')};
  overflow: hidden;
  color: ${({ theme }) => theme.colors.surface.onColor};

  &::after {
    content: '';
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      transparent 100%
    );
    display: ${({ isOpen }) => (isOpen ? 'none' : 'block')};
    position: absolute;
    height: 10px;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const Name = styled.h4`
  font-size: 16px;
  margin: 0;
`;

const LocationColumn = styled(Column)`
  margin-top: 0;
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 16px;
`;

interface Props {
  organization;
  onSelect;
  isSelected;
}

export const OrganizationListItem: FC<Props> = React.memo(
  ({ organization, onSelect, isSelected }) => {
    const {
      name,
      uniqueName,
      description,
      bannerId,
      address: { city },
      distance,
    } = organization;
    const [] = useState(false);

    return (
      <StyledListItem
        active={isSelected}
        onClick={() => onSelect(isSelected ? null : organization)}
      >
        <OrganizationRow>
          <Image src={getImageUrl(organization.bannerId, 480)} />
          <TextColumn flex>
            <Name>{name}</Name>
            <Description
              dangerouslySetInnerHTML={{ __html: description }}
              isOpen={isSelected}
            />
            {isSelected ? <Link to={'/' + uniqueName}>Läs mer</Link> : null}
          </TextColumn>
          <LocationColumn align="flex-end" justify="space-between">
            <div>{city}</div>
            {distance && <div>{parseInt(distance)} km</div>}
          </LocationColumn>
        </OrganizationRow>
      </StyledListItem>
    );
  }
);
